<template>
	<v-dialog v-model="dialog" scrollable width="700px" persistent>
		<template v-slot:activator="{ on, attrs }" v-if="locale != 'he'">
			<v-tooltip
				:right="!$vuetify.rtl"
				:left="$vuetify.rtl"
				v-bind="attrs"
				v-on="on"
			>
				<template v-slot:activator="{ on, attrs }">
					<v-icon
						v-bind="attrs"
						v-on="on"
						class="cursor-wr"
						@click.stop="dialog = true"
						:class="{ 'vertical-transform-wr': $vuetify.rtl }"
						>$vuetify.icons.values.volume_high</v-icon
					>
				</template>
				<span>{{ $t("app.listen") }}</span>
			</v-tooltip>
		</template>

		<AppDialog
			v-if="dialog"
			:heading="{
				label: 'app.hope_you_listening',
			}"
			:action="aptAction"
			@pause="pause()"
			@resume="resume()"
			@play-again="start()"
			@close="stop(), (dialog = false)"
		>
			<template v-slot:dialog-content>
				<div class="subtitle-1" v-html="text"></div>
			</template>
		</AppDialog>
	</v-dialog>
</template>

<script>
import { textToSpeechMixin } from "@/mixins/textToSpeechMixin.js";

export default {
	name: "TextToSpeech",

	mixins: [textToSpeechMixin],

	data() {
		return {
			dialog: false,
		};
	},

	watch: {
		dialog(newVal, oldVal) {
			if (newVal === true) {
				this.start();
			}
		},
	},

	created() {
		this.readyToListenEvents();
	},

	methods: {
		readyToListenEvents() {
			this.$eventBus.$on("stop-tts", (payload) => {
				// Stop only if it's running
				if (this.dialog) {
					this.stop();
				}
			});
		},
	},

	beforeDestroy() {
		this.$eventBus.$off("stop-tts");
	},
};
</script>
