import { callApi } from "./manager";

export const meetingSummary = {
	list: () => {
		return callApi(`/infra/meeting`, "get");
	},

	get: (payload) => {
		return callApi(`/infra/meeting/${payload.meeting_id}`, "get");
	},

	create: (payload) => {
		return callApi(`/infra/meeting/`, "post", payload);
	},

	update: (payload) => {
		return callApi(
			`/infra/meeting/${payload.meeting.id}/`,
			"put",
			payload.meeting,
		);
	},

	delete: (payload) => {
		return callApi(`/infra/meeting/${payload.meeting_id}/`, "delete");
	},

	// Agenda APIs
	listAgendas: (payload) => {
		return callApi(
			`/infra/meeting_agenda?meeting_id=${payload.meeting_id}`,
			"get",
		);
	},

	createAgenda: (payload) => {
		return callApi(`/infra/meeting_agenda/`, "post", payload);
	},

	updateAgenda: (payload) => {
		return callApi(
			`/infra/meeting_agenda/${payload.agenda.id}/`,
			"put",
			payload.agenda,
		);
	},

	deleteAgenda: (payload) => {
		return callApi(`/infra/meeting_agenda/${payload.agenda_id}/`, "delete");
	},

	// Todo APIs
	listTodoItems: (payload) => {
		return callApi(
			`/infra/meeting_todo?meeting_id=${payload.meeting_id}`,
			"get",
		);
	},

	createTodoItem: (payload) => {
		return callApi(`/infra/meeting_todo/`, "post", payload);
	},

	updateTodoItem: (payload) => {
		return callApi(
			`/infra/meeting_todo/${payload.todo_item.id}/`,
			"put",
			payload.todo_item,
		);
	},

	deleteTodoItem: (payload) => {
		return callApi(`/infra/meeting_todo/${payload.todo_id}/`, "delete");
	},
};
