import AppDialog from "@/components/ui/AppDialog.vue";
import { mapGetters } from "vuex";
import { removeHtmlTags } from "@/utils/helpers";

export const textToSpeechMixin = {
	props: {
		text: {
			required: true,
		},
		voice: {
			required: false,
		},
	},

	data() {
		return {
			header_label: "Preparing",
			paused: false,
			end: false,
		};
	},

	components: {
		AppDialog,
	},

	computed: {
		...mapGetters({
			locale: "globals/getLocale",
		}),

		textToSpeak() {
			// Remove HTML tags, and symbols other than ,.", etc.
			return removeHtmlTags(this.text).replace(/[&\/\\#()~'":*?<>{}]/g, "");
		},

		aptAction() {
			let label = null;
			let event = null;
			// If speech is end, show play again button
			if (this.end) {
				label = "app.play_again";
				event = "play-again";
			}
			// Else, show pause, resume button accordingly
			else {
				label = this.paused ? "app.resume" : "app.pause";
				event = this.paused ? "resume" : "pause";
			}
			return { label, event };
		},
	},

	methods: {
		isPlaying() {
			return responsiveVoice.voiceSupport();
		},

		getVoice() {
			if (this.voice) {
				return this.voice;
			} else {
				return this.locale === "ar"
					? "Arabic Female"
					: this.locale === "he"
					? "Hebrew Female"
					: "UK English Female";
			}
		},

		start() {
			try {
				let voice = this.getVoice();

				responsiveVoice.speak(this.textToSpeak, voice, {
					rate: 0.9,
					onstart: () => {
						this.end = false;
					},
					onend: () => {
						this.end = true;
					},
				});
			} catch (error) {
				this.$announce.error(error);
			}
		},

		pause() {
			try {
				responsiveVoice.pause();
				this.paused = true;
			} catch (error) {
				this.$announce.error(error);
			}
		},

		resume() {
			try {
				responsiveVoice.resume();
				this.paused = false;
			} catch (error) {
				this.$announce.error(error);
			}
		},

		stop() {
			try {
				responsiveVoice.cancel();
			} catch (error) {
				this.$announce.error(error);
			}
		},
	},
};
