const path_prefix = "/research";

export const dynamicRoutes = [
	{
		path: `${path_prefix}/cc3hhl_standard_map`,
		name: "cc3hhl_StandardMap",
		component: () =>
			import(
				/* webpackChunkName:"page.cc3hhl"*/ "@/pages/dynamic/cc3hhl_StandardMap.vue"
			),
		meta: {
			layout: "AppLayout",
			requiresAuth: true,
			header: {
				title: "app.choose_topic.mind_map.standard.board.title",
				subtitle: "app.tools.standard_mind_map.new_map.subtitle",
			},
			footer: {
				component: "Buttons",
				data: {
					rightBtn: {
						title: "app.next",
						event_name: "redirect-from-cc3hhl-standard-map",
					},
				},
			},
		},
	},
	{
		path: `${path_prefix}/bkvgew_standard_map`,
		name: "bkvgew_StandardMap",
		component: () =>
			import(
				/* webpackChunkName:"page.bkvgew"*/ "@/pages/dynamic/bkvgew_StandardMap.vue"
			),
		meta: {
			layout: "AppLayout",
			requiresAuth: true,
			header: {
				title: "app.choose_topic.mind_map.standard.board.title",
				subtitle: "app.tools.standard_mind_map.new_map.subtitle",
			},
			footer: {
				component: "Buttons",
				data: {
					rightBtn: {
						title: "app.next",
						event_name: "redirect-from-bkvgew-standard-map",
					},
				},
			},
		},
	},
	{
		path: `${path_prefix}/er4tfv_standard_map`,
		name: "er4tfv_StandardMap",
		component: () =>
			import(
				/* webpackChunkName:"page.er4tfv"*/ "@/pages/dynamic/er4tfv_StandardMap.vue"
			),
		meta: {
			layout: "AppLayout",
			requiresAuth: true,
			header: {
				title: "app.initial_field_mapping.title",
				subtitle: "app.tools.standard_mind_map.new_map.subtitle",
			},
			footer: {
				component: "Buttons",
				data: {
					rightBtn: {
						title: "app.next",
						event_name: "redirect-from-er4tfv-standard-map",
					},
				},
			},
		},
	},
	{
		path: `${path_prefix}/xl9baq_poster`,
		name: "xl9baq_Poster",
		component: () =>
			import(
				/* webpackChunkName:"page.xl9baq"*/ "@/pages/dynamic/xl9baq_Poster.vue"
			),
		meta: {
			layout: "AppLayout",
			requiresAuth: true,
			header: {
				title: "app.poster.title",
			},
			footer: {
				component: "Buttons",
				data: {
					rightBtn: {
						title: "app.next",
						event_name: "redirect-from-xl9baq_poster",
					},
				},
			},
		},
	},
];
