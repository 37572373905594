var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_vm._l((_vm.icons),function(item,index){return _c('v-list',{key:index,attrs:{"rounded":""}},[_c('v-tooltip',{attrs:{"right":!_vm.$vuetify.rtl,"left":_vm.$vuetify.rtl,"color":"wr_blue_1"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-list-item',{class:[
						'icons-container-wr cursor-wr',
						{ wr_blue_1: _vm.isIconActive(item) } ],attrs:{"disabled":!_vm.isAccessibilityIcon(item.name) &&
							!_vm.isDocumentIcon(item.name) &&
							!item.route}},[_c('v-list-item-icon',_vm._g(_vm._b({on:{"click":function($event){return _vm.proceed(item)}}},'v-list-item-icon',attrs,false),on),[_c('v-img',{attrs:{"src":require(("@/assets/images/svg/" + (item.name) + ".svg"))}}),(_vm.isNotificationIcon(item.name) && _vm.unReadMessagesCount)?_c('v-badge',{attrs:{"color":"error","content":_vm.unReadMessagesCount}}):_vm._e()],1)],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t(item.title)))])])],1)}),(_vm.dialog)?_c('DocumentDialog',{attrs:{"dialog":_vm.dialog},on:{"close":function($event){_vm.dialog = false}},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}}):_vm._e()],2)}
var staticRenderFns = []

export { render, staticRenderFns }