<template>
	<div>
		<v-toolbar color="wr_indigo_2" id="website-header-wr">
			<!-- Drawer icon (Show only on small devices) -->
			<v-app-bar-nav-icon
				@click="drawer = true"
				class="d-md-none"
				color="white"
			></v-app-bar-nav-icon>

			<!-- SKILLSX label -->
			<v-toolbar-title @click="redirectToHome()">
				<h2 class="cursor-wr" v-html="$t('app.skillsx_with_style')"></h2>
			</v-toolbar-title>

			<v-spacer></v-spacer>

			<!-- Accessibility Icon -->
			<AppButton
				label="app.accessibility"
				color="rgba(255, 255, 255, 0.1)"
				:dark="true"
				:class="['d-none', 'd-md-block']"
				icon="accessibility"
				@click="openAccessibilityMenu()"
			></AppButton>

			<!-- Language button -->
			<LocaleSwitcher></LocaleSwitcher>

			<!-- Display btn according to login status -->
			<AppButton
				:label="isAuthorized ? 'app.get_started' : 'app.sign_in'"
				:class="['d-none d-md-block']"
				:icon="!isAuthorized ? 'user' : null"
				@click="isAuthorized ? redirectTo() : (dialog = true)"
			></AppButton>

			<template v-slot:extension v-if="!isMobileBreakpoint">
				<v-tabs
					v-model="tab"
					background-color="wr_indigo_2"
					active-class="font-weight-bold"
					class="d-none d-md-block"
					centered
					slider-color="greenAccent2"
				>
					<template v-for="(item, index) in options">
						<v-tab
							v-if="item.show"
							:key="index"
							:href="`#${item.scroll_id}`"
							@click="scrollTo(item.scroll_id)"
							class="subtitle-1 white--text px-10"
						>
							<v-icon
								color="greenAccent2"
								:left="!$vuetify.rtl"
								:right="$vuetify.rtl"
								>{{ $vuetify.icons.values[item.icon] }}</v-icon
							>
							{{ $t(item.title) }}
						</v-tab>
					</template>
				</v-tabs>
			</template>
		</v-toolbar>

		<!-- Add a navigation bar -->
		<v-navigation-drawer
			v-model="drawer"
			fixed
			temporary
			:style="{ zIndex: 1000000 }"
			:right="$vuetify.rtl"
			:left="!$vuetify.rtl"
			class="wr_indigo_2"
		>
			<v-list nav dense>
				<v-btn icon>
					<v-icon @click="drawer = false" class="white--text">{{
						$vuetify.icons.values[$vuetify.rtl ? "right_arrow" : "left_arrow"]
					}}</v-icon>
				</v-btn>
				<v-list-item-group class="mt-5" active-class="font-weight-bold">
					<template v-for="(item, index) in options">
						<v-list-item :key="index" v-if="item.show">
							<v-list-item-icon>
								<v-icon color="greenAccent2">{{
									$vuetify.icons.values[item.icon]
								}}</v-icon>
							</v-list-item-icon>
							<v-list-item-title
								:href="`#${item.scroll_id}`"
								class="white--text"
								@click="scrollTo(item.scroll_id), (drawer = false)"
								>{{ $t(item.title) }}</v-list-item-title
							>
						</v-list-item>
					</template>
				</v-list-item-group>
			</v-list>
		</v-navigation-drawer>

		<!-- Open authentication form for login -->
		<TheLogin
			v-if="dialog"
			v-model="dialog"
			:dialog="dialog"
			@close="dialog = false"
		></TheLogin>
	</div>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import TheLogin from "@/components/layout/TheLogin.vue";
import LocaleSwitcher from "@/components/website/LocaleSwitcher.vue";
import { projectsManageMixin } from "@/mixins/projectsManageMixin.js";

export default {
	name: "WebsiteHeader",

	mixins: [projectsManageMixin],

	data() {
		return {
			tab: 0,
			drawer: false,
			dialog: false,
		};
	},

	components: {
		TheLogin,
		LocaleSwitcher,
	},

	computed: {
		...mapGetters({
			isAuthorized: "auth/isAuthorized",
			isMentor: "user/isMentor",
			userLang: "user/getLanguage",
		}),

		...mapState({
			projects: (state) => state.user.projects,
		}),

		isHomeRoute() {
			return this.$route.name == "Home";
		},

		isMobileBreakpoint() {
			return window.innerWidth < this.$vuetify.breakpoint.thresholds.sm;
		},

		options() {
			return [
				{
					title: "app.home.value",
					scroll_id: "value",
					icon: "marker_check",
					show: this.isHomeRoute,
				},
				{
					title: "app.home.platform",
					scroll_id: "platform",
					icon: "airplane_landing",
					show: this.isHomeRoute,
				},
				{
					title: "app.home.journeys",
					scroll_id: "journey",
					icon: "arrow_all",
					show: this.isHomeRoute,
				},
				{
					title: "app.home.program_alias",
					scroll_id: "program",
					icon: "content_paste",
					show: this.isHomeRoute && this.userLang != "en",
				},
				{
					title: "app.home.listening_you",
					scroll_id: "contact_us",
					icon: "human_greeting",
					show: true,
				},
				{
					title: "app.home.about",
					scroll_id: "about",
					icon: "info_circle_filled",
					show: this.isHomeRoute,
				},
			];
		},
	},

	methods: {
		async redirectTo() {
			try {
				this.$loader.start();

				this.projects && this.projects.length > 1 && !this.isMentor
					? await this.$router.push({
							name: "Projects",
					  })
					: await this.setUpDataForDashboard();
			} catch (error) {
				this.$announce.error(error);
			} finally {
				this.$loader.stop();
			}
		},

		redirectToHome() {
			this.$router.push({
				name: "Home",
				query: {
					lang: this.$route.query.lang,
				},
			});
		},

		scrollTo(id) {
			this.$nextTick(() => {
				document.getElementById(id).scrollIntoView({
					behavior: "smooth",
				});
			});
		},

		openAccessibilityMenu() {
			try {
				this.$loader.start();

				this.$nextTick(() => {
					window.interdeal.a11y.openMenu();
				});
			} catch (error) {
				this.$announce.error(error);
			} finally {
				this.$loader.stop();
			}
		},
	},
};
</script>

<style scoped>
h2 {
	text-shadow: 1px 1px rgb(199, 130, 130) !important;
}
</style>
