<template>
	<v-footer
		id="the-footer-wr"
		color="white"
		class="elevation-11"
		absolute
		bottom
		height="62"
		width="100%"
		:style="styleObject"
	>
		<v-card flat width="100%">
			<div>
				<component
					:is="$route.meta.footer.component"
					:data="$route.meta.footer.data"
				></component>
			</div>
		</v-card>
	</v-footer>
</template>

<script>
import * as footerComponents from "@/components/layout/footers";
import { mapGetters } from "vuex";

export default {
	name: "TheFooter",

	components: {
		...footerComponents,
	},

	computed: {
		...mapGetters({
			isMentor: "user/isMentor",
		}),

		styleObject() {
			if (!this.isMentor) {
				return {};
			}
			return {
				[this.$vuetify.rtl ? "right" : "left"]: "79px",
			};
		},
	},
};
</script>
