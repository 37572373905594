import { tools } from "./home";
import { knowledgeOrganizersRoutes } from "./knowledgeOrganizers/index";
import { interview } from "./interview";
import { observation } from "./observation";
import { meeting } from "./meeting";
import { meetingSummary } from "./meetingSummary";
import { standardMindmap } from "./standard_mind_map";
import { wizardMindmap } from "./wizard_mind_map";
import { setPriority } from "./set_priority";
import { editor } from "./editor";
import { questionCreator } from "./question_creator";

export const toolsRoutes = [
	...tools,
	...knowledgeOrganizersRoutes,
	...interview,
	...observation,
	...meeting,
	...meetingSummary,
	...standardMindmap,
	...wizardMindmap,
	...setPriority,
	...editor,
	...questionCreator,
];
