<template>
	<v-card flat tile id="the-header-wr">
		<v-card-text class="px-10 pt-2 pb-1">
			<v-row no-gutters>
				<v-col class="pe-3">
					<!-- If route consist component -->
					<component
						v-if="$route.meta.header.component"
						:is="$route.meta.header.component"
					></component>

					<!-- If route consist title and subtitle already -->
					<TheHeaderInterface
						v-else
						:title="$route.meta.header.title"
						:subtitle="$route.meta.header.subtitle"
					></TheHeaderInterface>
				</v-col>

				<v-col sm="1" class="mt-1" align="end">
					<TheLogout></TheLogout>
				</v-col>
			</v-row>
		</v-card-text>
	</v-card>
</template>

<script>
import * as headerComponents from "@/components/layout/headers";
import TheHeaderInterface from "@/components/layout/TheHeaderInterface.vue";
import TheLogout from "@/components/layout/TheLogout.vue";
import { mapGetters } from "vuex";

export default {
	name: "TheHeader",

	components: {
		TheHeaderInterface,
		...headerComponents,
		TheLogout,
	},

	computed: {
		...mapGetters({
			isMentor: "user/isMentor",
		}),
	},
};
</script>
