<template>
	<div>
		<v-list v-for="(item, index) in icons" :key="index" rounded>
			<v-tooltip :right="!$vuetify.rtl" :left="$vuetify.rtl" color="wr_blue_1">
				<template v-slot:activator="{ on, attrs }">
					<v-list-item
						:class="[
							'icons-container-wr cursor-wr',
							{ wr_blue_1: isIconActive(item) },
						]"
						:disabled="
							!isAccessibilityIcon(item.name) &&
								!isDocumentIcon(item.name) &&
								!item.route
						"
					>
						<v-list-item-icon v-bind="attrs" v-on="on" @click="proceed(item)">
							<v-img
								:src="require(`@/assets/images/svg/${item.name}.svg`)"
							></v-img>
							<v-badge
								v-if="isNotificationIcon(item.name) && unReadMessagesCount"
								color="error"
								:content="unReadMessagesCount"
							>
							</v-badge>
						</v-list-item-icon>
					</v-list-item>
				</template>
				<span>{{ $t(item.title) }}</span>
			</v-tooltip>
		</v-list>

		<!-- Dialog box for research document icon -->
		<DocumentDialog
			v-if="dialog"
			v-model="dialog"
			:dialog="dialog"
			@close="dialog = false"
		></DocumentDialog>
	</div>
</template>

<script>
import { isArrayHavingItem } from "@/utils/helpers";
import { mapGetters } from "vuex";
import DocumentDialog from "@/components/shared/DocumentDialog.vue";

export default {
	name: "TheSideNavIcons",

	props: {
		icons: {
			required: true,
			type: Array,
		},
	},

	components: {
		DocumentDialog,
	},

	data() {
		return {
			dialog: false,
		};
	},

	computed: {
		...mapGetters({
			unReadMessagesCount: "notification/getUnReadNotificationsSum",
		}),
	},

	methods: {
		isDocumentIcon(payload) {
			return payload == "notepad";
		},

		isAccessibilityIcon(payload) {
			return payload == "accessibility";
		},

		isNotificationIcon(payload) {
			return payload == "notification";
		},

		isGroupIcon(payload) {
			return payload == "network-group";
		},

		isIconActive(item) {
			try {
				// If document icon, then check dialog status
				if (this.isDocumentIcon(item.name)) {
					return this.dialog;
				}
				// If other icon, then check if route is available
				if (!item.route) return false;
				// Match available route with current route
				return isArrayHavingItem(item.related_routes, this.$route.name);
			} catch (error) {
				this.$announce.error(error);
			}
		},

		async proceed(item) {
			try {
				this.$loader.start();
				// Open dialog if clicks on document icon
				if (this.isDocumentIcon(item.name)) {
					this.dialog = true;
					return;
				}
				// Open accessibility wizard if clicks on accessibility icon
				if (this.isAccessibilityIcon(item.name)) {
					this.$nextTick(() => {
						window.interdeal.a11y.openMenu();
					});
					return;
				}
				// Clear all breadcrumbs if clicks on group icon
				if (this.isGroupIcon(item.name)) {
					this.$store.commit("group/RESET_BREADCRUMBS");
				}
				// Close All Steps and Stage
				this.$store.commit("research/INACTIVE_MODULES_AND_STAGES");
				// Push to the respected route
				this.$router.push({
					name: item.route.name,
				});
			} catch (error) {
				this.$announce.error(error);
			} finally {
				this.$loader.stop();
			}
		},
	},
};
</script>

<style scoped>
.icons-container-wr {
	transition: 0.9s !important;
	margin-bottom: 0 !important;
}

.icons-container-wr:hover {
	background: var(--v-wr_blue_1-base) !important;
}
</style>
