<template>
	<v-btn
		depressed
		:dark="dark"
		:text="text"
		:tile="tile"
		:small="small"
		:block="block"
		:color="color"
		:large="large"
		:loading="loading"
		:rounded="rounded"
		:outlined="outlined"
		:disabled="disabled"
		:min-width="min_width"
		:style="prop_style"
		:class="prop_class"
		@click="$emit('click')"
	>
		<v-icon v-if="icon && left_icon" left>
			{{ $vuetify.icons.values[icon] }}
		</v-icon>
		<template v-if="label">
			{{ $t(label) }}
		</template>
		<v-icon v-if="icon && !left_icon" right>
			{{ $vuetify.icons.values[icon] }}
		</v-icon>
	</v-btn>
</template>

<script>
export default {
	name: "AppButton",

	props: {
		label: {
			type: String,
		},
		icon: {
			type: String,
		},
		left_icon: {
			default: false,
			type: Boolean,
		},
		color: {
			default: "amberDarken1",
			type: String,
		},
		rounded: {
			default: true,
			type: Boolean,
		},
		min_width: {
			default: 120,
			type: Number,
		},
		large: {
			default: false,
			type: Boolean,
		},
		disabled: {
			default: false,
			type: Boolean,
		},
		dark: {
			default: false,
		},
		block: {
			required: false,
		},
		small: {
			required: false,
		},
		outlined: {
			default: false,
		},
		text: {
			default: false,
		},
		tile: {
			default: false,
		},
		loading: {
			default: false,
		},
		prop_style: {
			type: Object,
		},
		prop_class: {
			type: Array,
		},
	},
};
</script>

<style></style>
