const path_prefix = "/research/mentor/student";

export const student = [
	{
		path: `${path_prefix}/group/:group_id/list`,
		name: "StudentsList",
		component: () =>
			import(
				/* webpackChunkName:"page.m-sl"*/ "@/pages/users/mentor/student/List.vue"
			),
		meta: {
			layout: "MentorLayout",
			requiresAuth: true,
			header: true,
			footer: {
				component: "MentorStudentFooter",
			},
		},
	},
	{
		path: `${path_prefix}/:id`,
		name: "StudentOverview",
		component: () =>
			import(
				/* webpackChunkName:"page.m-so"*/ "@/pages/users/mentor/student/Overview.vue"
			),
		meta: {
			layout: "MentorLayout",
			requiresAuth: true,
			header: true,
			footer: {
				component: "MentorStudentFooter",
			},
		},
	},
	{
		path: `${path_prefix}/:id/research_paper`,
		name: "StudentResearchPaper",
		component: () =>
			import(
				/* webpackChunkName:"page.m-srp"*/ "@/pages/users/mentor/student/ResearchPaper.vue"
			),
		meta: {
			layout: "MentorLayout",
			requiresAuth: true,
			header: true,
			footer: {
				component: "MentorStudentFooter",
			},
		},
	},
	{
		path: `${path_prefix}/:id/interviews`,
		name: "StudentInterviews",
		component: () =>
			import(
				/* webpackChunkName:"page.m-si"*/ "@/pages/users/mentor/student/Interviews.vue"
			),
		meta: {
			layout: "MentorLayout",
			requiresAuth: true,
			header: true,
			footer: {
				component: "MentorStudentFooter",
			},
		},
	},
];
