import { i18n } from "@/plugins/i18n";

export default {
	required: (value) => {
		if (value === "" || value == null) {
			return i18n.t("app.input_field_required");
		}
		return true;
	},

	inBetween: (value) => {
		if (
			!isNaN(parseFloat(value)) &&
			parseInt(value) >= 0 &&
			parseInt(value) <= 10
		)
			return true;
		return false;
	},

	max(maxNum) {
		return (v) =>
			(v || "").length <= Number(maxNum) ||
			i18n.t("app.max_chars_allowed_params", {
				length: maxNum,
			});
	},

	min(minNum) {
		return (v) =>
			(v || "") >= Number(minNum) ||
			i18n.t("app.min_chars_required", {
				count: minNum,
			});
	},

	isValidURL(str) {
		var urlPattern = new RegExp(
			"^(https?:\\/\\/)?" + // validate protocol
			"((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" + // validate domain name
			"((\\d{1,3}\\.){3}\\d{1,3}))" + // validate OR ip (v4) address
			"(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" + // validate port and path
			"(\\?[;&a-z\\d%_.~+=-]*)?" + // validate query string
				"(\\#[-a-z\\d_]*)?$",
			"i",
		); // validate fragment locator

		return urlPattern.test(str) || i18n.t("app.invalid_url");
	},

	strongPassword(v) {
		return (v ?? " ").match(
			/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%&*?_-])[A-Za-z\d!@#$%&*?_-]{8,}$/,
		)
			? true
			: i18n.t("app.pswd_create_instructions");
	},
};
