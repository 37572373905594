<template>
	<v-dialog v-model="dialog" persistent max-width="500">
		<v-card flat>
			<v-card-text class="pa-10">
				<v-row align="center" justify="center">
					<v-col cols="12" align="center">
						<Loading></Loading>
					</v-col>
					<v-col
						cols="12"
						class="headline black--text text-center font-weight-bold"
					>
						{{ $t(data.title) }}
					</v-col>
					<v-col cols="12" class="subtitle-1 text-center pt-0">
						{{ $t(data.body) }}
					</v-col>
				</v-row>
			</v-card-text>
		</v-card>
	</v-dialog>
</template>

<script>
import Loading from "@/components/plugins/Loading.vue";

export default {
	name: "LoaderDialog",

	components: {
		Loading,
	},

	data() {
		return {
			dialog: false,
			data: {
				title: "app.processing_request",
				body: "app.dont_refresh_page",
			},
		};
	},

	created() {
		this.$eventBus.$on("start-loader", (payload = this.data) => {
			this.dialog = true;
			if (payload.title) {
				this.data.title = payload.title;
			}
			if (payload.body) {
				this.data.body = payload.body;
			}
		});
		this.$eventBus.$on("stop-loader", (payload) => {
			this.dialog = false;
		});
	},
};
</script>
