//Steps for PlanResearch
export const planResearchSteps = [
	{
		value: 1,
		name: "chooseStartingDate",
		component_name: "StartDate",
		previous_step: null,
		next_step: "addAvailability",
		back_event: null,
		next_event: "ptr-set-start-date",
		header_title: "app.plan_research.step_1.title",
		header_subtitle: "app.plan_research.step_1.subtitle",
		video_id: "394n3hr630",
	},
	{
		value: 2,
		name: "addAvailability",
		component_name: "AvailableDays",
		previous_step: "chooseStartingDate",
		next_step: "showTimeline",
		back_event: "ptr-redirect-back",
		next_event: "ptr-calculate-research-plan",
		header_title: "app.plan_research.step_2.title",
		header_subtitle: "app.plan_research.step_2.subtitle",
	},
	{
		value: 3,
		name: "showTimeline",
		component_name: "Timeline",
		previous_step: "addAvailability",
		next_step: "showResearchPlanInCalendar",
		back_event: "ptr-redirect-back",
		next_event: "ptr-redirect-next",
		header_title: "app.plan_research.step_3.title",
		header_subtitle: "app.plan_research.step_3.subtitle",
	},
	{
		value: 4,
		name: "showResearchPlanInCalendar",
		component_name: "ResearchPlanInCalendar",
		previous_step: "showTimeline",
		next_step: null,
		back_event: "ptr-redirect-back",
		next_event: "ptr-confirm-plan",
		header_title: "app.plan_research.step_4.title",
		header_subtitle: "app.plan_research.step_4.subtitle",
	},
];

export const planSearchStrategySteps = [
	{
		value: 1,
		name: "KeywordsImport",
		component_name: "KeywordsImport",
		previous_step: null,
		next_step: "KeywordsList",
		back_event: null,
		next_event: "pss-redirect-next",
		header_subtitle: "app.plan_search_strategy.step_1.subtitle",
	},
	{
		value: 2,
		name: "KeywordsList",
		component_name: "KeywordsList",
		previous_step: "KeywordsImport",
		next_step: "KeywordsSelect",
		back_event: "pss-redirect-back",
		next_event: "pss-validate-min-keywords",
		header_subtitle: "app.plan_search_strategy.step_2.subtitle",
	},
	{
		value: 3,
		name: "KeywordsSelect",
		component_name: "KeywordsSelect",
		previous_step: "KeywordsList",
		next_step: null,
		back_event: "pss-redirect-back",
		next_event: "pss-validate-max-keywords",
		header_subtitle: "app.plan_search_strategy.step_3.subtitle",
	},
];

// Steps For Choose Question Section's Whiteboard
export const chooseQuestionBoardSteps = [
	{
		value: 1,
		name: "addNotesWithTimer",
		component_name: "WhiteBoard",
		previous_step: null,
		next_step: "chooseTagsForNotes",
		back_event: null,
		next_event: "crq-validate-notes-status",
		header_title: "app.choose_question.step_1.title",
		header_subtitle: "app.choose_question.step_1.subtitle",
	},
	{
		value: 2,
		name: "chooseTagsForNotes",
		component_name: "WhiteBoard",
		previous_step: "addNotesWithTimer",
		next_step: "writeProsAndCons",
		back_event: "crq-redirect-back",
		next_event: "crq-validate-tags-presence",
		header_title: "app.choose_question.step_2.title",
		header_subtitle: "app.choose_question.step_2.subtitle",
	},
	{
		value: 3,
		name: "writeProsAndCons",
		component_name: "WriteProsAndCons",
		previous_step: "chooseTagsForNotes",
		next_step: "interchangeTags",
		back_event: "crq-redirect-back",
		next_event: "crq-validate-pros-and-cons",
		header_title: "app.choose_question.step_3.title",
		header_subtitle: "app.choose_question.step_3.subtitle",
	},
	{
		value: 4,
		name: "interchangeTags",
		component_name: "WhiteBoard",
		previous_step: "writeProsAndCons",
		next_step: "selectFavNotes",
		back_event: "crq-redirect-back",
		next_event: "crq-validate-tags-interchanging",
		header_title: "app.choose_question.step_4.title",
		header_subtitle: "app.choose_question.step_4.subtitle",
	},
	{
		value: 5,
		name: "selectFavNotes",
		component_name: "WhiteBoard",
		previous_step: "interchangeTags",
		next_step: "reviewSelectedNotes",
		back_event: "crq-redirect-back",
		next_event: "crq-validate-fav-notes",
		header_title: "app.choose_question.step_5.title",
		header_subtitle: "app.choose_question.step_5.subtitle",
	},
	{
		value: 6,
		name: "reviewSelectedNotes",
		component_name: "ReviewSelectedNotes",
		previous_step: "selectFavNotes",
		next_step: "questionnaire",
		back_event: "crq-redirect-back",
		next_event: "crq-validate-research-question",
		header_title: "app.choose_question.step_6.title",
		header_subtitle: "app.choose_question.step_6.subtitle",
	},
	{
		value: 7,
		name: "questionnaire",
		component_name: "Summary",
		previous_step: "reviewSelectedNotes",
		next_step: null,
		back_event: "crq-redirect-back",
		next_event: "crq-submit-summary",
		header_title: "app.choose_question.step_7.title",
		header_subtitle: "app.choose_question.step_7.subtitle",
	},
];

// Steps for wizard mind map
export const wizardMindmapSteps = [
	{
		value: 1,
		name: "prepareTitleNode",
		previous_step: null,
		next_step: "prepareChildNodes",
		back_event: null,
		next_event: "wmm-validate-nodes",
		header_title: "app.choose_topic.mind_map.wizard.step_1.title",
		header_subtitle: "app.choose_topic.mind_map.wizard.step_1.subtitle",
	},
	{
		value: 2,
		name: "prepareChildNodes",
		previous_step: "prepareTitleNode",
		next_step: "arrangeNodes",
		back_event: "wmm-validate-nodes-on-back",
		next_event: "wmm-validate-nodes",
		header_title: "app.choose_topic.mind_map.wizard.step_2.title",
		header_subtitle: "app.choose_topic.mind_map.wizard.step_2.subtitle",
	},
	{
		value: 3,
		name: "arrangeNodes",
		previous_step: "prepareChildNodes",
		next_step: "prepareConnections",
		back_event: "wmm-validate-nodes-on-back",
		next_event: "wmm-validate-nodes",
		header_title: "app.choose_topic.mind_map.wizard.step_3.title",
		header_subtitle: "app.choose_topic.mind_map.wizard.step_3.subtitle",
	},
	{
		value: 4,
		name: "prepareConnections",
		previous_step: "arrangeNodes",
		next_step: "enrichMap",
		back_event: "wmm-validate-nodes-on-back",
		next_event: "wmm-validate-nodes",
		header_title: "app.choose_topic.mind_map.wizard.step_4.title",
		header_subtitle: "app.choose_topic.mind_map.wizard.step_4.subtitle",
	},
	{
		value: 5,
		name: "enrichMap",
		previous_step: "prepareConnections",
		next_step: "selectFavNodes",
		back_event: "wmm-validate-nodes-on-back",
		next_event: "wmm-validate-nodes",
		header_title: "app.choose_topic.mind_map.wizard.step_5.title",
		header_subtitle: "app.choose_topic.mind_map.wizard.step_5.subtitle",
	},
	{
		value: 6,
		name: "selectFavNodes",
		previous_step: "enrichMap",
		next_step: null,
		back_event: "wmm-validate-nodes-on-back",
		next_event: "wmm-validate-fav-nodes",
		header_title: "app.choose_topic.mind_map.wizard.step_6.title",
		header_subtitle: "app.choose_topic.mind_map.wizard.step_6.subtitle",
	},
];

// Steps for standard mind map
export const standardMindmapSteps = [
	{
		value: 1,
		name: "prepareMapsAndSelectOne",
		previous_step: null,
		next_step: "selectFavNodes",
		back_event: null,
		next_event: "smm-validate-initial-step",
		header_subtitle: "app.choose_topic.mind_map.standard.step_1.subtitle",
	},
	{
		value: 2,
		name: "selectFavNodes",
		previous_step: "prepareMapsAndSelectOne",
		next_step: null,
		back_event: "smm-redirect-back",
		next_event: "smm-validate-final-step",
		header_subtitle: "app.choose_topic.mind_map.standard.step_2.subtitle",
	},
];

// Steps for table chart
export const tableChartSteps = [
	{
		value: 1,
		name: "prepareTitle",
		previous_step: null,
		next_step: "initiateRowColumns",
		back_event: null,
		next_event: "tcm-validate-title",
		header_subtitle: "app.choose_topic.mind_map.table_chart.step_1.subtitle",
	},
	{
		value: 2,
		name: "initiateRowColumns",
		previous_step: "prepareTitle",
		next_step: "manageContent",
		back_event: "tcm-redirect-back",
		next_event: "tcm-redirect-next",
		header_subtitle: "app.choose_topic.mind_map.table_chart.step_2.subtitle",
	},
	{
		value: 3,
		name: "manageContent",
		previous_step: "initiateRowColumns",
		next_step: "selectFavTerms",
		back_event: "tcm-redirect-back",
		next_event: "tcm-redirect-next",
		header_subtitle: "app.choose_topic.mind_map.table_chart.step_3.subtitle",
	},
	{
		value: 4,
		name: "selectFavTerms",
		previous_step: "manageContent",
		next_step: null,
		back_event: "tcm-redirect-back",
		next_event: "tcm-finish-work",
		header_subtitle: "app.choose_topic.mind_map.table_chart.step_4.subtitle",
	},
];

// Steps for prioritization tool
export const prioritizationToolSteps = [
	{
		value: 1,
		name: "subtopicsTable",
		component_name: "SubtopicsTable",
		previous_step: null,
		next_step: "scoreStatistics",
		back_event: null,
		next_event: "pt-submit-subtopic-questionnaire",
		header_title: "app.choose_topic.prioritization_tool.step_1.title",
		header_subtitle: "app.choose_topic.prioritization_tool.step_1.subtitle",
	},
	{
		value: 2,
		name: "scoreStatistics",
		component_name: "ScoreStatistics",
		previous_step: "subtopicsTable",
		next_step: null,
		back_event: "pt-draft-subtopics-questionnaire",
		next_event: "pt-finish-work",
		header_title: "app.choose_topic.prioritization_tool.step_2.title",
		header_subtitle: "app.choose_topic.prioritization_tool.step_2.subtitle",
	},
];

// Steps for interactions and networking
export const interactionNetworkingSteps = [
	{
		value: 1,
		name: "interviewOnBoard",
		component_name: "InterviewArrangements",
		next_event: "in-move-to-summary",
		header_subtitle: "app.interaction_and_networking.step_1.subtitle",
	},
	{
		value: 2,
		name: "interactSummary",
		component_name: "InteractSummary",
		next_event: "in-finish",
		header_subtitle: "app.interaction_and_networking.step_2.subtitle",
	},
];

export const setPrioritySteps = [
	{
		value: 1,
		name: "subtopicsTable",
		component_name: "SetPriority",
		previous_step: null,
		next_step: "scoreStatistics",
		back_event: null,
		next_event: "sp-submit-subtopic-questionnaire",
		header_title: "app.tools.set_priority.step_1.title",
		header_subtitle: "app.tools.set_priority.step_1.subtitle",
	},
	{
		value: 2,
		name: "scoreStatistics",
		component_name: "DisplayScore",
		previous_step: "subtopicsTable",
		next_step: null,
		back_event: "sp-draft-subtopics-questionnaire",
		next_event: null,
		header_title: "app.tools.set_priority.step_2.title",
		header_subtitle: "app.tools.set_priority.step_2.subtitle",
	},
];
