var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"scrollable":"","width":"700px","persistent":""},scopedSlots:_vm._u([(_vm.locale != 'he')?{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-tooltip',_vm._g(_vm._b({attrs:{"right":!_vm.$vuetify.rtl,"left":_vm.$vuetify.rtl},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"cursor-wr",class:{ 'vertical-transform-wr': _vm.$vuetify.rtl },on:{"click":function($event){$event.stopPropagation();_vm.dialog = true}}},'v-icon',attrs,false),on),[_vm._v("$vuetify.icons.values.volume_high")])]}}],null,true)},'v-tooltip',attrs,false),on),[_c('span',[_vm._v(_vm._s(_vm.$t("app.listen")))])])]}}:null],null,true),model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[(_vm.dialog)?_c('AppDialog',{attrs:{"heading":{
			label: 'app.hope_you_listening',
		},"action":_vm.aptAction},on:{"pause":function($event){return _vm.pause()},"resume":function($event){return _vm.resume()},"play-again":function($event){return _vm.start()},"close":function($event){_vm.stop(), (_vm.dialog = false)}},scopedSlots:_vm._u([{key:"dialog-content",fn:function(){return [_c('div',{staticClass:"subtitle-1",domProps:{"innerHTML":_vm._s(_vm.text)}})]},proxy:true}],null,false,2328338224)}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }