<template>
	<v-dialog :value="dialog" scrollable width="700px" persistent>
		<AppDialog
			:heading="{
				label: 'app.hope_you_listening',
			}"
			:action="aptAction"
			@pause="pause()"
			@resume="resume()"
			@play-again="start()"
			@close="stop(), $emit('close')"
		>
			<template v-slot:dialog-content>
				<div class="subtitle-1" v-html="text"></div>
			</template>
		</AppDialog>
	</v-dialog>
</template>

<script>
import { textToSpeechMixin } from "@/mixins/textToSpeechMixin.js";

export default {
	name: "EtherTextToSpeech",

	mixins: [textToSpeechMixin],

	props: {
		dialog: {
			required: true,
		},
	},

	mounted() {
		this.start();
	},
};
</script>
