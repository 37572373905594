export const agoraRoutes = {
	index: ["AgoraIndexPage"],
	recording: ["AgoraRecordingPage"],
};

export const researchRoutes = {
	// M1
	planResearch: "PlanResearch",
	// M2
	defineQuestionPlan: "ChooseResearchQuestionPlan",
	defineQuestionSummary: "DefineQuestionSummary",
	gatherInfo: "GatherInformation",
	chooseQuestion: "ChooseResearchQuestion",
	// M2-Choose theme
	chooseTheme: "ChooseTheme",
	chooseThemeDatabase: "ChooseThemeDatabase",
	chooseThemeSummary: "ChooseThemeSummary",
	// M2-Choose topic
	chooseTopic: "ChooseTopic",
	chooseTopicStandardMindmap: "ChooseTopicStandardMindmap",
	chooseTopicWizardMindmap: "ChooseTopicWizardMindmap",
	chooseTopicTableChart: "ChooseTopicTableChart",
	chooseTopicPrioritizationTool: "ChooseTopicPrioritizationTool",
	chooseTopicSummary: "ChooseTopicSummary",
	// M3
	gatherSourcesPlan: "GatherSourceResearchPlan",
	planSearchStrategy: "PlanSearchStrategy",
	createSourceList: "CreateSourceList",
	sourceDocumentation: "SourceDocumentation",
	sourceEvaluation: "SourceEvaluation",
	sourceSelection: "SourceSelection",
	gatherSourcesSummary: "GatherSourcesSummary",
	// M4
	writePlan: "WriteResearchPlan",
	researchPaper: "ResearchPaper",
	writeSummary: "WriteResearchSummary",
	// M5
	reflectJourneyPlan: "ReflectJourneyResearchPlan",
	achievementsAndChallenges: "AchievementsAndChallenges",
	interactionsAndNetworking: "InteractionsAndNetworking",
	lifeLessons: "LifeLessons",
	reflectJourneySummary: "ReflectJourneySummary",
	// M6
	presentResearchPlan: "PresentResearchPlan",
	preparePresentation: "PreparePresentation",
	practicePresentation: "PracticePresentation",
	schedulePresentation: "SchedulePresentation",
	presentSummary: "PresentResearchSummary",
	// Dynamic
	cc3hhl_StandardMap: "cc3hhl_StandardMap",
	bkvgew_StandardMap: "bkvgew_StandardMap",
	er4tfv_StandardMap: "er4tfv_StandardMap",
	xl9baq_Poster: "xl9baq_Poster",
};

export const mentorRoutes = {
	...agoraRoutes,
	calendar: ["MentorCalendar"],
	dashboard: ["MentorDashboard"],
	notifications: ["MentorNotifications"],
	settings: ["MentorSettings"],
	tools: [
		"MentorTools",
		// Meeting
		"MentorMeet",
		// Meeting Summary
		"MentorMeetingsList",
		"MentorMeetingSummaryExplore",
		// Standard Mind Map
		"MentorStandardMindmapList",
		"MentorStandardMindmapExplore",
		// Wizard mind map
		"MentorToolsWizardmindMap",
		"MentorWizardMindmapsList",
		"MentorWizardMindmapExplore",
		// Editor
		"MentorToolsEditor",
		"MentorEditorExplorePage",
	],
	student: [
		"StudentOverview",
		"StudentResearchPaper",
		"StudentsList",
		"StudentInterviews",
	],
	group: ["MentorGroups"],
};

export const studentRoutes = {
	...agoraRoutes,
	calendar: ["ResearchCalendar"],
	dashboard: ["UserDashboard"],
	notifications: ["Notifications"],
	settings: ["UserSettings"],
	help: ["Help"],
	tools: [
		"Tools",
		// Priority
		"SetPriority",
		// Knowledge organizers
		"KnowledgeOrganizers",
		"PositiveNegative",
		"PositiveNegativeStatement",
		"AnalyzingEvents",
		"Decisions",
		"AnalyzingPerson",
		"Beliefs",
		"AnalyzingPhenomenon",
		"Literature",
		"Genres",
		"History",
		"AnalyzingPrimarySources",
		"Science",
		"Bible",
		"CivicStudies",
		"CivicAnalyzingPrimarySource",
		// Interview
		"InterviewsList",
		"Interview",
		// Observation
		"ObservationsList",
		"Observation",
		// Meeting
		"Meet",
		// Meeting Summary
		"MeetingsList",
		"MeetingSummaryExplore",
		// Standard Mind Map
		"StandardMindmapList",
		"StandardMindmapExplore",
		// Wizard Mind Map
		"ToolsWizardMindmap",
		"WizardMindmapsList",
		"WizardMindmapExplore",
		// Editor
		"ToolsEditor",
		"EditorExplorePage",
		// Question Creator
		"QuestionCreator",
		// Collaborate Research Paper
		"ToolsCollaborateResearchPaper",
	],
	projects: ["Projects"],
	work: [
		"Work",
		"StandardMapWork",
		"SourcesWork",
		"QuestionBoardWork",
		"WizardMapWork",
		"QuestionnaireWork",
		"ResearchPaperWork",
	],
};

export const adminRoutes = {
	...researchRoutes,
	/**
	 * Becasue an admin can also perform research journey so
	 * use the same routes of student. Agora routes are also)
	 * included in student routes.
	 */
	...studentRoutes, // For admin to perform the research jounrey
	users: ["AdminUsers"],
	group: ["AdminGroups"],
	student: [
		"AdminStudentOverview",
		"AdminStudentResearchPaper",
		"AdminStudentInterviews",
	],
};

export const routesAuthority = {
	// Only admin can access
	adminReachableRoutes: Object.values(adminRoutes).flat(),

	// Only mentor can access
	mentorReachableRoutes: Object.values(mentorRoutes).flat(),

	// Only student can access
	studentReachableRoutes: Object.values(studentRoutes).flat(),
};
