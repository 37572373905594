var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"scrollable":"","width":"1000px","persistent":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-tooltip',_vm._g(_vm._b({attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"cursor-wr",class:{ 'vertical-transform-wr': _vm.$vuetify.rtl },on:{"click":function($event){_vm.dialog = true}}},'v-icon',attrs,false),on),[_vm._v("$vuetify.icons.values.translate")])]}}],null,true)},'v-tooltip',attrs,false),on),[_c('span',[_vm._v(_vm._s(_vm.$t("app.translate")))])])]}}]),model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[(_vm.dialog)?_c('AppDialog',{attrs:{"heading":{
			label: 'app.tools.translator.title',
		},"height":650},on:{"close":function($event){return _vm.resetAndCloseDialog()}},scopedSlots:_vm._u([{key:"dialog-content",fn:function(){return [_c('v-card',{attrs:{"flat":""}},[_c('v-card-text',{staticClass:"pt-0"},[(!_vm.isSourceLangSelected)?_c('div',{staticClass:"error--text subtitle-1"},[_vm._v(" * "+_vm._s(_vm.$t("app.ntfy.err.source_lang_missing"))+" ")]):_vm._e(),(_vm.isSourceLangSelected && !_vm.isSourceTextPresent)?_c('div',{staticClass:"error--text subtitle-1"},[_vm._v(" * "+_vm._s(_vm.$t("app.ntfy.err.source_text_missing"))+" ")]):_vm._e(),(
							_vm.isSourceLangSelected &&
								_vm.isSourceTextPresent &&
								!_vm.isTargetLangSelected
						)?_c('div',{staticClass:"error--text subtitle-1"},[_vm._v(" * "+_vm._s(_vm.$t("app.ntfy.err.target_lang_missing"))+" ")]):_vm._e()]),_c('v-card-text',[_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',[_c('v-form',{ref:"source_form"},[_c('v-autocomplete',{attrs:{"items":_vm.$defines.TRANSLATOR_LANGUAGES,"item-text":"name","hide-no-data":"","outlined":"","hide-details":"","hide-selected":"","label":_vm.$t('app.source_lang'),"placeholder":_vm.$t('app.placeholder.type_to_search'),"return-object":"","prepend-inner-icon":"$vuetify.icons.values.language","clearable":"","color":"greenAccent2","clear-icon":_vm.$vuetify.icons.values.close},on:{"click:clear":function($event){return _vm.clearTarget()}},model:{value:(_vm.source_lang),callback:function ($$v) {_vm.source_lang=$$v},expression:"source_lang"}})],1)],1),_c('v-col',[_c('v-form',{ref:"target_form"},[_c('v-autocomplete',{attrs:{"items":_vm.$defines.TRANSLATOR_LANGUAGES,"item-text":"name","outlined":"","hide-details":"","disabled":!_vm.isSourceLangSelected || !_vm.isSourceTextPresent,"label":_vm.$t('app.target_lang'),"placeholder":_vm.$t('app.placeholder.type_to_search'),"return-object":"","prepend-inner-icon":"$vuetify.icons.values.language","clearable":"","color":"greenAccent2","clear-icon":_vm.$vuetify.icons.values.close},on:{"input":function($event){return _vm.translate()},"click:clear":function($event){return _vm.clearTarget()}},model:{value:(_vm.target_lang),callback:function ($$v) {_vm.target_lang=$$v},expression:"target_lang"}})],1)],1)],1),_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{staticClass:"relative-pos-wr"},[_c('v-card',{attrs:{"outlined":"","tile":"","height":"300"}},[_c('v-textarea',{directives:[{name:"debounce",rawName:"v-debounce:1s",value:(_vm.translate),expression:"translate",arg:"1s"}],staticClass:"title",style:({
										direction: _vm.source_lang && _vm.source_lang.rtl ? 'rtl' : 'ltr',
									}),attrs:{"flat":"","solo":"","placeholder":_vm.$t('app.placeholder.enter_text'),"height":"230","counter":1000,"disabled":!_vm.isSourceLangSelected,"maxlength":"1000","clearable":"","color":"greenAccent2","background-color":"white","clear-icon":_vm.$vuetify.icons.values.close},on:{"click:clear":function($event){return _vm.clearTarget()}},model:{value:(_vm.source_text),callback:function ($$v) {_vm.source_text=$$v},expression:"source_text"}}),_c('v-footer',{staticClass:"white",attrs:{"absolute":"","bottom":"","height":"45"}},[(_vm.isSourceLangSelected)?_c('SpeechToText',{attrs:{"text":_vm.source_text},on:{"speechend":function($event){_vm.source_text = $event.text}}}):_vm._e()],1)],1)],1),_c('v-col',{staticClass:"relative-pos-wr"},[_c('v-card',{staticClass:"greyLighten4 pa-2",style:({
									direction:
										_vm.isTargetLangSelected && _vm.target_lang.rtl ? 'rtl' : 'ltr',
								}),attrs:{"tile":"","outlined":"","height":"300"}},[(_vm.translating)?_c('v-container',{attrs:{"fill-height":"","fluid":""}},[_c('v-row',{attrs:{"align":"center","justify":"center"}},[_c('v-col',{staticClass:"text-h5",attrs:{"align":"center"}},[_vm._v(" "+_vm._s(_vm.$t("app.translating"))+"... ")])],1)],1):_vm._e(),(
										_vm.isTargetLangSelected && !_vm.translating && _vm.translated_text
									)?[_c('v-card-text',{staticClass:"black--text title translated-text-wr"},[_vm._v(" "+_vm._s(_vm.translated_text)+" ")]),_c('v-footer',{attrs:{"absolute":"","bottom":"","height":"45"}},[_c('CopyToClipboard',{attrs:{"message":_vm.translated_text}}),_c('span',{staticClass:"mx-3"},[(_vm.target_lang.voice)?_c('TextToSpeech',{attrs:{"text":_vm.translated_text,"voice":_vm.target_lang.voice}}):_vm._e()],1)],1)]:_vm._e()],2)],1)],1)],1)],1)]},proxy:true}],null,false,2144892359)}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }